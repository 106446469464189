.page__banner {
  width: 100%;
  height: 620px;
  padding: 0 25px;
}

.page__banner__info {
  position: absolute;
  bottom: 60px;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 20px;
  color: white;
  text-transform: uppercase;
}

.page__banner__info__text {
  font-size: var(--font-xl);
}

.page__banner__info__title {
  font-size: var(--font-xxxl);
  line-height: 103.18%;
}

@media screen and (max-width: 800px) {
  .page__banner {
    height: 420px;
  }
  .page__banner__info {
    bottom: 25px;
    gap: 8px;
  }
  .page__banner__info__title {
    font-size: 3.5rem;
  }
}
