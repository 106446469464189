.menu {
  position: fixed;
  top: 0;
  right: 0;
  display: none;
  flex-direction: column;
  width: 500px;
  height: 100vh;
  padding: 30px 40px 0 40px;
  background: rgba(0, 0, 0, 0.52);
  backdrop-filter: blur(11.5px);
  transform: translateX(110vw);
  transition: transform 0.2s;
  z-index: 2;
}

.menu__header {
  position: relative;
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.menu__header__logo__container {
  width: 230px;
  height: 60px;
}

.menu__header__logo {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.menu__header__button {
  position: absolute;
  top: 2px;
  right: -15px;
  width: 28px;
  height: 28px;
  object-fit: cover;
}

.menu__header__button__line {
  position: relative;
  width: 28px;
  height: 4px;
  background: white;
  border-radius: 5px;
}

.left {
  top: 10px;
  left: 0;
  transform: rotate(45deg);
}

.right {
  top: 6px;
  right: 0;
  transform: rotate(-45deg);
}

.menu__info {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 40px 0;
}

.menu__info__icons {
  display: flex;
  align-items: center;
  gap: 25px;
}

.menu__nav {
  display: flex;
  flex-direction: column;
  gap: 25px;
  color: var(--white);
}

.menu__nav__link {
  font-weight: 700;
  font-size: var(--font-l);
}

.menu__info__icon__container {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
}

.menu__info__icon {
  cursor: pointer;
  width: 100%;
  height: 100%;
  object-fit: contain;
}

@media screen and (max-width: 1290px) {
  .menu {
    display: flex;
  }
}

@media screen and (max-width: 800px) {
  .menu {
    width: 100vw;
  }
}
