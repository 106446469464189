.wwu {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 150px;
  background: white;
  border-radius: 17px;
  padding: 0 80px;
  gap: 70px;
}

.wwu__logo {
  display: block;
  width: auto;
  height: 60px;
}

.wwu__logo__vertical {
  display: none;
}

@media screen and (max-width: 900px) {
  .wwu {
    gap: 30px;
    padding: 20px 30px;
    height: auto;
  }
  .wwu__logo {
    display: none;
  }
  .wwu__logo__vertical {
    display: block;
  }
}
