.benefits__summary__item {
  display: flex;
  align-items: center;
  border-radius: 17px;
  background: white;
}

.benefits__summary__item__title {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 300px;
  height: 100%;
  padding: 80px 60px;
  flex-shrink: 0;
  font-weight: 700;
  font-size: var(--font-xxl);
  color: white;
  border-radius: 17px;
}

.benefits__summary__item__description {
  padding: 60px 110px;
  font-size: var(--font-m);
}

@media screen and (max-width: 1290px) {
  .benefits__summary__item {
    flex-direction: column;
    width: 100%;
  }
  .benefits__summary__item__title {
    width: 100%;
    height: 100px;
    padding: 0 30px;
  }
  .benefits__summary__item__description {
    padding: 15px 30px;
  }
}
