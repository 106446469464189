.categories__container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 47px;
}

@media screen and (max-width: 1290px) {
  .categories__container {
    flex-direction: row;
    flex-wrap: wrap;
    gap: 20px;
  }
}
