.description {
  font-size: 1.2rem;
}

@media screen and (max-width: 800px) {
  .description {
    font-size: var(--font-m) !important;
    margin: 20px 0 !important;
  }
}
