.not__found__redirect {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  margin: 65px 0 80px;
}

.not__found__redirect__image {
  width: 350px;
  height: 245px;
}

.not__found__redirect__link {
  display: flex;
  align-items: center;
  gap: 13px;
  font-weight: 700;
  font-size: var(--font-m);
}
