.footer {
  background: var(--brown);
  color: white;
  padding: 60px 0 90px;
}

.footer__row {
  display: flex;
  justify-content: space-between;
  font-size: var(--font-m);
}

.footer__column {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.footer__column__logo {
  width: 84px;
  height: 92px;
}

.footer__column__link {
  font-weight: 700;
}

.footer__column__button {
  color: var(--brown);
  background: white;
  width: 190px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: var(--font-s);
  font-weight: 700;
  border-radius: 65px;
  transition: 0.1s;
}

.footer__column__button:hover {
  transform: scale(1.05);
}

.footer__column__info {
  position: relative;
  display: flex;
  align-items: center;
  gap: 20px;
}

.footer__column__info__line__icon {
  position: absolute;
  top: 0;
  left: 0;
  width: 20px;
  height: 20px;
  object-fit: contain;
}

.footer__column__text {
  padding: 0 0 0 35px;
}

@media screen and (max-width: 800px) {
  .footer {
    padding: 30px 0 40px;
  }
  .footer__row {
    flex-direction: column;
    align-items: center;
    gap: 30px;
  }
  .footer__column {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
}
