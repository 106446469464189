.container {
  position: relative;
  max-width: 900px;
  width: 100%;
  height: 100%;
  margin: 0 auto;
}

@media screen and (max-width: 1000px) {
  .container {
    padding: 0 25px;
  }
}
