.triple__column__section {
  display: flex;
  gap: 30px;
  margin: 40px 0 0 0;
}

.triple__column__section__item {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  width: 100%;
  height: 400px;
  background: white;
  font-size: var(--font-s);
  padding: 0 0 30px 0;
  border-radius: 17px;
}

.triple__column__section__item__title {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: var(--font-xxl);
  height: 195px;
  border-radius: 16.2662px;
  background: var(--orange);
}

.triple__column__section__item__button {
  width: calc(100% - 60px);
  text-align: center;
  font-size: var(--font-m);
  font-weight: 700;
  color: var(--black);
  padding: 10px 0;
  border-radius: 41px;
  border: 2px solid var(--black);
  white-space: nowrap;
  transition: 0.1s;
}

.triple__column__section__item__button:hover {
  color: var(--white);
  background: var(--black);
}

.bg-green {
  background: var(--green);
}

.bg-dimgray {
  background: var(--dimgray);
}

.inverted {
  color: var(--white);
  background: var(--black);
  border: 2px solid var(--black);
}

.inverted:hover {
  color: var(--black);
  background: var(--white);
}

@media screen and (max-width: 800px) {
  .triple__column__section {
    flex-direction: column;
    gap: 20px;
  }
  .triple__column__section__item__title {
    height: 120px;
  }
  .triple__column__section__item {
    height: auto;
    gap: 25px;
  }
}
