.logistics__item {
  display: flex;
  align-items: center;
  background: white;
  border-radius: 17px;
}

.logistics__item__info {
  padding: 40px 30px 40px 40px;
  display: flex;
  flex-direction: column;
}

.logistics__item__info__title {
  font-size: var(--font-xl);
}

.logistic__item__info__location {
  margin: 20px 0;
}

.logistics__row {
  display: flex;
  gap: 28px;
}

.logistics__column {
  display: flex;
  flex-direction: column;
  gap: 15px;
  width: 50%;
}

.logistics__column__item {
  flex: 1;
}

.logistics__column__item,
.logistics__description {
  display: flex;
  flex-direction: column;
  padding: 16px 24px;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
  border-radius: 20px;
  gap: 8px;
}

.logistics__description {
  margin: 15px 0 0 0;
  height: 250px;
}

.logistics__item__image__container {
  width: 300px;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: stretch;
  overflow: hidden;
  border-radius: 17px;
}

.logistics__item__image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.inline {
  display: flex;
  flex-direction: row;
  flex-grow: 0;
}

.logistics__item__image__mobile {
  display: none;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

@media screen and (max-width: 900px) {
  .logistics__item__image {
    display: none;
  }
  .logistics__item__image__mobile {
    display: block;
  }
  .logistics__item {
    flex-direction: column;
  }
  .logistics__item__info {
    padding: 10px;
  }
  .logistics__item__image__container {
    width: 100%;
    height: 150px;
  }
  .logistics__item__image {
    width: 150%;
    height: 150%;
    object-fit: cover;
  }
  .logistics__description {
    margin: 10px 0 0 0;
    height: auto;
  }
  .logistics__row {
    flex-direction: column;
    gap: 10px;
  }
  .logistics__column {
    width: 100%;
    flex-direction: column;
  }
  .logistics__item__info__title {
    padding: 0 0 0 20px;
  }
  .logistic__item__info__location {
    margin: 10px 0;
    padding: 0 0 0 20px;
  }
}
