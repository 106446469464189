.questions__section {
  display: flex;
  gap: 30px;
}

@media screen and (max-width: 1290px) {
  .questions__section {
    flex-direction: column;
    align-items: center;
    gap: 15px;
  }
}
