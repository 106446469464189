.contact__location__map {
  width: 100%;
  height: 500px;
  margin: 40px 0 80px;
  border-radius: 20px;
}

.contact__location__info {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
}

.contact__location__info__line {
  display: flex;
  align-items: center;
  gap: 20px;
}

.contact__location__info__line__text {
  font-size: var(--font-m);
}

@media screen and (max-width: 800px) {
  .contact__location__info {
    flex-direction: column;
    gap: 20px;
    align-items: flex-start;
  }
}
