.questions__section__item {
  width: 100%;
  background: white;
  border-radius: 20px;
  padding: 36px 26px 66px 32px;
}

.questions__section__item__icon {
  width: 43px;
  height: 43px;
}

.questions__section__item__title {
  margin: 30px 0 20px;
  font-size: var(--font-l);
  font-weight: 700;
}

@media screen and (max-width: 1290px) {
  .questions__section__item {
    padding: 20px 30px;
  }
  .questions__section__item__title {
    margin: 15px 0 10px;
  }
  .questions__section__item__icon {
    width: 35px;
    height: 35px;
  }
}
