.contact__form {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.contact__form__title {
  font-weight: 700;
  font-size: var(--font-xxl);
  margin: 80px 0 0 0;
}

.contact__form__description {
  margin: 20px 0 40px;
  font-size: var(--font-l);
}

.contact__form__inputarea {
  display: flex;
  align-items: center;
  width: 100%;
  height: 250px;
  gap: 30px;
}

.contact__form__inputarea__details {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 520px;
  height: 100%;
  gap: 15px;
}

.contact__form__inputarea__details__input__container {
  position: relative;
  width: 100%;
  height: 100%;
}

.contact__form__inputarea__details__input {
  padding: 10px 30px;
}

.contact__form__inputarea__details__input::placeholder,
.contact__form__inputarea__details__textarea::placeholder {
  color: var(--placeholderClr);
}

.contact__form__inputarea__details__textarea,
.contact__form__inputarea__details__input {
  width: 100%;
  height: 100%;
  border-radius: 20px;
  font-size: var(--font-m);
  border: 2px solid var(--darkgray);
}

.contact__form__inputarea__details__input,
.contact__form__inputarea__details__textarea {
  background: var(--darkgray);
}

.contact__form__inputarea__details__error {
  position: absolute;
  top: -10px;
  left: 10px;
  width: auto;
  padding: 2px 10px;
  background: var(--darkgray);
  border-radius: 8px;
}

.contact__form__inputarea__details__textarea {
  padding: 20px 30px;
}

.contact__form__button {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin: 25px 0 90px;
}

.contact__form__button__mobile {
  display: none;
}

@media screen and (max-width: 800px) {
  .contact__form__title {
    margin: 40px 0 0 0;
    font-size: var(--font-xl);
  }
  .contact__form__description {
    margin: 10px 0 15px;
    font-size: var(--font-m);
  }
  .contact__form__inputarea {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 15px;
  }
  .contact__form__inputarea__details {
    width: 100%;
  }

  .contact__form__inputarea__details__textarea {
    width: 100%;
    height: 220px;
  }
  .contact__form__inputarea {
    height: auto;
  }
  .contact__form__button__mobile {
    display: block;
    width: 100%;
    margin: 20px 0 60px;
  }
  .contact__form__button {
    display: none;
  }
}
