.benefits__list__item {
  position: relative;
  width: 31%;
  border-radius: 20px;
  overflow: hidden;
  color: white;
}

.benefits__list__item__image__container {
  width: 100%;
  height: 100%;
}

.benefits__list__item__image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.benefits__list__item__title {
  position: absolute;
  width: 100%;
  height: 60px;
  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(5.5px);
  left: 0;
  bottom: 0;
  padding: 15px 30px;
  display: flex;
  align-items: center;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
}

@media screen and (max-width: 1290px) {
  .benefits__list__item {
    width: 45%;
    height: 190px;
  }
}

@media screen and (max-width: 800px) {
  .benefits__list__item__title {
    padding: 5px 16px 10px;
  }
}
