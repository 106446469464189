.category__section__item {
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 17px;
  color: white;
  overflow: hidden;
}

.category__section__item__info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 35px 50px;
  width: 40%;
}

.category__section__item__info__icon {
  width: 20px;
  height: 20px;
}

.category__section__item__info__title {
  font-size: var(--font-xxl);
  white-space: nowrap;
}

.category__section__item__info__description {
  font-size: var(--font-s);
  margin: 8px 0 0 0;
}

.category__section__item__image__container {
  width: 60%;
  overflow: hidden;
  border-radius: 17px;
  align-self: stretch;
}

.category__section__item__image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  transform: scale(1.05);
}

@media screen and (max-width: 800px) {
  .category__section__item {
    flex-direction: column !important;
  }
  .category__section__item__image__container {
    width: 100%;
    height: 180px;
  }
  .category__section__item__info {
    width: 100%;
    padding: 15px 30px 30px;
  }
  .category__section__item__info__title {
    font-size: var(--font-xl);
  }
}
