.not__found__banner {
  width: 100%;
  height: 620px;
  background: var(--errorPageBg);
}

.not__found__banner__info {
  position: absolute;
  bottom: 60px;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 40px;
  color: white;
}

.not__found__banner__info__text {
  font-size: var(--font-xxl);
}

.not__found__banner__info__title {
  font-size: var(--font-xxxl);
  line-height: 103.18%;
  text-transform: uppercase;
}
