.benefits {
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: var(--font-m);
}

.benefits__list {
  display: flex;
  flex-wrap: wrap;
  gap: 28px;
}

.benefits__description {
  list-style-type: disc;
  padding: 0 0 0 25px;
  margin: 40px 0 100px;
}

.benefits__summary {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
}

@media screen and (max-width: 1290px) {
  .benefits__list {
    justify-content: center;
    gap: 20px;
  }
  .benefits__description {
    margin: 20px 0 40px;
    padding: 0 0 0 30px;
  }
}
