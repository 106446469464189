.iwd {
  display: flex;
  gap: 30px;
}

.iwd__info {
  width: 50%;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.iwd__image__container {
  width: 50%;
  border-radius: 17px;
  overflow: hidden;
}

.iwd__image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.iwd__info__title {
  font-size: var(--font-xl);
}

.iwd__info__description {
  font-size: var(--font-m);
}

@media screen and (max-width: 800px) {
  .iwd {
    flex-direction: column;
    align-items: center;
    gap: 10px;
  }
  .iwd__info {
    gap: 10px;
  }
  .iwd__image__container {
    width: 100%;
  }
  .iwd__info {
    width: 100%;
  }
}
