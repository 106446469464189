.header__container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  z-index: 2;
  transition: transform 0.3s 0.1s;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: white;
  font-weight: 700;
  max-width: 1350px;
  width: 100%;
  padding: 25px 70px;
  border-radius: 0px 0px 30px 30px;
  background: rgba(32, 32, 32, 0.5);
  backdrop-filter: blur(25.5px);
}

.header__logo__container {
  width: 62px;
  height: 68px;
}

.header__logo {
  width: 100%;
  height: 100%;
  flex-shrink: 0;
}

.header__nav {
  display: flex;
  gap: 30px;
  margin: 0 68px 0 45px;
}

.header__aside {
  display: flex;
  gap: 20px;
  align-items: center;
}

.header__aside__icon__container {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
}

.header__aside__icon {
  cursor: pointer;
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.header__aside__icon__text {
  white-space: nowrap;
  position: absolute;
  bottom: -18px;
  right: -8px;
}

.header__aside__icon__container__mobile {
  display: none;
  width: 25px;
  height: 25px;
}

.header__aside__icon__mobile {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.contact {
  transform: scale(0.7);
}

@media screen and (max-width: 1290px) {
  .header {
    padding: 18px 22px;
    border-radius: unset;
    border-radius: 0px 0px 30px 30px;
  }
  .header__logo__container {
    width: 30px;
    height: 32px;
  }
  .header__nav {
    display: none;
  }
  .header__aside__icon {
    display: none;
  }
  .header__aside__copy {
    display: none;
  }
  .header__aside__icon__container__mobile {
    display: block;
  }
  .header__aside__button {
    display: none;
  }
  .menu {
    display: block;
  }
}
