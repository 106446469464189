.horizontal__dual__column__section {
  display: flex;
  flex-direction: column;
  gap: 25px;
}

.horizontal__dual__column__section__image {
  min-width: 600px;
  width: 100%;
}
