.best__variant {
  display: flex;
  flex-direction: column;
  background: white;
  border-radius: 17px;
  margin: 40px 0;
}

.best__variant__info {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.best__variant__info__title {
  font-size: var(--font-xxl);
  width: 50%;
  padding: 60px 90px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
}

@media screen and (max-width: 1290px) {
  .best__variant__info {
    flex-direction: column;
    padding: 20px 30px;
    gap: 20px;
  }
  .best__variant__info__title {
    width: 100%;
    padding: 0;
  }
  .best__variant__image {
    min-height: 120px;
    object-fit: cover;
    border-radius: 17px;
  }
}
