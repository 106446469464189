.infrastructure {
  display: flex;
  flex-direction: column;
  gap: 44px;
  margin: 30px 0 0 0;
}

@media screen and (max-width: 1000px) {
  .infrastructure {
    gap: 20px;
  }
}
