.main__quote {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: white;
  border-radius: 20px;
  padding: 45px 0;
  margin: 40px 0;
}

.main__quote__title {
  font-weight: 700;
  font-size: 1.7rem;
}

.main__quote__logo {
  position: absolute;
  top: 50%;
  right: 50px;
  transform: translateY(-50%);
  height: 60px;
  width: auto;
}

.main__quote__logo__mobile {
  display: none;
  width: 28px;
  height: 31px;
}

@media screen and (max-width: 900px) {
  .main__quote {
    flex-direction: column;
    gap: 16px;
    padding: 18px 0;
  }
  .main__quote__title {
    font-size: var(--font-m);
  }
  .main__quote__logo {
    display: none;
  }
  .main__quote__logo__mobile {
    display: block;
  }
}
