.product__list__item {
  width: 100%;
  display: flex;
  align-items: center;
  border-radius: 17px;
  color: white;
  font-weight: 700;
}

.product__list__item__info {
  padding: 80px 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 40%;
}

.product__list__item__info__icon {
  width: 20px;
  height: 20px;
}

.product__list__item__info__title {
  font-size: var(--font-xxl);
  width: 220px;
}

.product__list__item__image__container {
  width: 60%;
  overflow: hidden;
  border-radius: 17px;
}

.product__list__item__image {
  display: block;
  object-fit: cover;
  object-position: center;
}

@media screen and (max-width: 1290px) {
  .product__list__item {
    width: 45%;
    display: block;
    text-align: center;
  }
  .product__list__item__info {
    position: relative;
    width: 100%;
    padding: 8px 15px;
    height: 80px;
    justify-content: center;
  }
  .product__list__item__info__title {
    font-size: var(--font-l);
  }
  .product__list__item__info__icon {
    position: absolute;
    top: 10px;
    right: 10px;
    width: 8px;
    height: 8px;
  }
  .product__list__item__image__container {
    width: 100%;
    height: 220px;
  }
  .product__list__item__image {
    width: 100%;
    height: 100%;
  }
}
