.dual__column__section {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 30px;
}

.dual__column__section__item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 50%;
}

.dual__column__section__item__image {
  min-width: 300px;
  width: 100%;
  border-radius: 20px;
}

.dual__column__section__item__title {
  margin: 25px 0 0 0;
  font-size: var(--font-xl);
}

.dual__column__section__item__description {
  font-size: var(--font-m);
  margin: 25px 0;
}

@media screen and (max-width: 1290px) {
  .dual__column__section {
    flex-direction: column-reverse;
    align-items: center;
    gap: 30px;
  }
  .dual__column__section__item {
    width: 100%;
  }
  .dual__column__section__item__description {
    margin: 15px 0 0 0;
  }
}
