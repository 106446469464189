.title {
  font-size: var(--font-xxl);
  text-transform: uppercase;
}

@media screen and (max-width: 1290px) {
  .title {
    margin: 40px 0 20px !important;
  }
}

@media screen and (max-width: 800px) {
  .title {
    font-size: var(--font-xl);
  }
}
