.logistics {
  display: flex;
  flex-direction: column;
  gap: 60px;
}

@media screen and (max-width: 900px) {
  .logistics {
    gap: 20px;
  }
}
